$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_fonts';
@import '../mixins';

@keyframes imageGridBackground {
    0% {
        background-position: 50% 0;
    }
    100% {
        background-position: (-50%) 0;
    }
}

.link {
    // required to render focus outline on focusable element containing block-like
    // elements
    display: block;
    position: relative;
}
.product {
    cursor: pointer;
}
.hasDivider {
    border-bottom: solid 1px $sassy-color-dolphin;
}

.hasLightBox {
    background: $sassy-color-white;
    border-color: transparent;
    margin: -$sassy-spacing-medium (-$sassy-spacing-small) 0; // parens to stop variables from combining, without parens negative variables would combine with adjacent variables
    @include sassy-breakpoint-below(tablet-portrait) {
        margin: 0;
        padding: $sassy-spacing-small;
    }
}

.imageWrapper {
    padding: $sassy-spacing-small;
    padding-bottom: $sassy-spacing-x-small;
    position: relative;
}
.lightBoxImage {
    padding: $sassy-spacing-medium $sassy-spacing-small;
}

.hideImagePadding {
    padding: 0;
}

.hideImageSidePadding {
    padding-left: 0;
    padding-right: 0;
}

.imageContainer {
    @include image-sizes;
    position: relative;
    box-sizing: border-box;
}

.centerImage {
    margin: auto;
}

.centerVerticaly {
    margin: auto 0;
}

.swiperImageContainer {
    @extend .imageContainer;
    user-select: none;
}

.image {
    // Positioning needed to center the image
    // in whatever the container it gets to be inside (eg. LazyLoad wrapper)
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.swiperImage {
    @extend .image;
    margin: auto;
    padding: 0 1px;
}

.swiperLink {
    width: 100%;
    height: 100%;
}

.carouselWrapper {
    position: relative;
    height: 100%;
    width: 100%;
}

.arrows {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0;
    visibility: hidden;

    &.visible {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.5s;
    }
}

.nextArrow {
    right: 0;
}

.prevArrow {
    left: 0;
}

.productDetailWrapper {
    display: flex;
    padding-bottom: $sassy-spacing-x-small;
    margin-top: -$sassy-spacing-x-small;

    .hasLightBox & {
        padding-left: $sassy-spacing-x-small;
    }
    &.hideDivider {
        padding: 0;
    }

    @include sassy-breakpoint-below(mobile) {
        position: relative;
        height: $sassy-spacing-x-large;
        flex-direction: column;
        justify-content: flex-end;
    }

    @include sassy-breakpoint-above(tablet-portrait) {
        align-items: center;
    }
}

.dimensions {
    @include sassy-font-body(x-small);
    overflow: hidden;
    color: $sassy-color-iron;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;

    @include sassy-breakpoint-below(mobile) {
        margin-top: 6px;
        width: 90%; // the width is at 90% for mobile to prevent dimensions from overlapping with the quickview button
        line-height: 1.5;
    }
}
