$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_webkitLineClamp';
@import '../variables';

$content-width: 310px;

.container {
    position: relative;
    width: 100%;
    max-width: $content-width + ($sassy-spacing-small * 2);
    box-sizing: border-box;
    border: 1px solid;
    border-color: transparent;

    &.activeHover {
        border-color: $sassy-color-dolphin;
        transition: border-color 0.5s;
        color: currentColor;
    }

    &:hover {
        border-color: $sassy-color-dolphin;
        transition: border-color 0.5s;
        color: currentColor;

        .footer {
            opacity: 1;
            transition: opacity 0.5s;
        }
    }

    &.disableBorder {
        border-color: transparent !important;
    }

    &.alignCenter {
        margin: auto;
    }

    @media (hover: none) {
        &:hover {
            border-color: transparent;
        }
    }
}

.footer {
    @include sassy-font-body($size: x-small);
    display: flex;
    justify-content: space-between;

    opacity: 0;
    max-width: $content-width;

    &.activeHover {
        opacity: 1;
        transition: opacity 0.5s;
    }
}

.content {
    box-sizing: border-box;
    max-width: $content-width;
    margin: 0 $sassy-spacing-small;
    padding: $sassy-spacing-medium 0 $sassy-spacing-small 0;

    @include sassy-breakpoint-below(tablet-portrait) {
        margin: $sassy-spacing-small $sassy-spacing-x-small 0 $sassy-spacing-x-small;
        padding: 0;
    }

    &.lightBoxLayout {
        @include sassy-breakpoint-below(tablet-portrait) {
            margin: 0;
            padding: 0;
        }
    }
}

.disableContentMargin {
    margin: 0;
}
.disableContentPadding {
    padding: 0;
}

.info {
    margin-top: $TILE_SPECIFIC-spacing-small;
}

.infoLightBox {
    margin: $TILE_SPECIFIC-spacing-small (-$sassy-spacing-small) 0; // parens to stop variables from combining, without parens negative variables would combine with adjacent variables
    @include sassy-breakpoint-below(tablet-portrait) {
        margin: 0;
        padding: $sassy-spacing-small 0 0;
    }
}

.pricePlaceHolder {
    min-height: 21px;
    margin: $TILE_SPECIFIC-spacing-x-small 0 $TILE_SPECIFIC-spacing-small 0;
}

.titleWrapper {
    display: flex;
    justify-content: space-between;
}

.tileLink {
    @include sassy-buyer-link-no-decoration;
    &:hover {
        color: currentColor;
    }
}

.titleLink {
    overflow: hidden;
}

.title {
    flex: 1;
    @include webkitLineClamp($lineClamp: 2);
}

.titleHeader {
    @include sassy-font-body($size: small, $type: heavy);
    margin: 0;
}

.creator {
    @include sassy-font-body($size: small);
}

.artTitle {
    @include sassy-font-body($size: small, $type: heavy);
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.artTitleNoCreator {
    margin-top: 21px;
}

.ellipsis {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.measurementsWrapper {
    @include sassy-font-body($size: x-small);
    color: $sassy-color-iron;
}

.isProminentTile {
    max-width: none;
}

.productContainer {
    position: relative;
}

.quickViewLightBox {
    position: absolute;
    bottom: $TILE_SPECIFIC-spacing-small;
    right: 0;
    @include sassy-breakpoint-below(tablet-portrait) {
        right: $TILE_SPECIFIC-spacing-small;
        margin-bottom: -$sassy-spacing-x-small; // not ideal, but at least no need to propagate it down to QuickViewButton
    }
}

.priceBidsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
