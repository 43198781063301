$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_spacing';
@import '../variables';

@mixin fontStyle($size: medium) {
    @include sassy-font-body($size);

    &.priceBold {
        @include sassy-font-body($size, $type: heavy);
    }
}

.price {
    display: flex;
    flex-wrap: wrap;

    &.fontSmall {
        @include fontStyle($size: small);
    }

    &.fontMedium {
        @include fontStyle();
    }

    &.fontLarge {
        @include fontStyle($size: large);
    }

    &.imageSmall {
        padding-top: $sassy-spacing-x-small;
        @include sassy-breakpoint-below(tablet-portrait) {
            @include fontStyle($size: small);
        }
    }
    &.imageLarge,
    &.imageMedium {
        padding-top: $sassy-spacing-small;

        @include sassy-breakpoint-below(tablet-portrait) {
            @include fontStyle(small);
            padding-top: $sassy-spacing-x-small;
        }
    }

    &.imageXLarge {
        @include fontStyle;
        padding: $sassy-spacing-x-small 0;
    }

    &.isSbTile {
        padding-top: $TILE_SPECIFIC-spacing-x-small;
        padding-bottom: $sassy-spacing-x-small;
    }

    &.reduceMargins {
        padding-top: 2px;
        padding-bottom: 2px;
    }
}

.priceLabel {
    padding-right: $sassy-spacing-x-small;
    @include sassy-breakpoint-below(tablet-portrait) {
        padding-right: $sassy-spacing-small;
    }
    @include sassy-breakpoint-below(mobile) {
        padding-right: 4px;
    }
}

.leftPipeDivider {
    &:before {
        content: '';
        display: inline-block;
        height: 12px;
        width: 1px;
        margin-right: $sassy-spacing-x-small;
        background: $sassy-color-dolphin;
        @include sassy-breakpoint-below(tablet-portrait) {
            margin-right: $sassy-spacing-xx-small;
        }
    }
}

.percentageOff {
    display: flex;
    align-items: center;

    &.sale {
        color: $sassy-color-alert-secondary;
    }
    &.net {
        color: $sassy-color-trade-primary;
    }
    &.isSbTile {
        &:before {
            display: none;
        }
    }

    @extend .leftPipeDivider;
}

.textTypePriceWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: $sassy-spacing-x-small;

    .price {
        padding-bottom: 0;

        &:not(:last-child) {
            padding-right: $sassy-spacing-x-small;

            @include sassy-breakpoint-below(tablet-portrait) {
                padding-right: $sassy-spacing-xx-small;
            }
        }
    }

    .leftPipeDivider {
        align-items: center;

        &::before {
            background: $sassy-color-pitch-black;
        }
    }
}

.priceLineThrough {
    > .priceLabel {
        text-decoration: line-through;
    }
}

.alert {
    color: $sassy-color-alert-secondary;
}

.paddleContainer {
    display: inline-block;
    width: 10px;
    margin-right: $sassy-spacing-x-small;
}

.paddle {
    display: block;
    margin-top: 4px;
}
