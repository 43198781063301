$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_buyerLinks';

.standardLink {
    @include sassy-buyer-standard-link;
}
.standardLinkNoUnderline {
    @include sassy-buyer-standard-link($underline: none);
}
.invertedLink {
    @include sassy-buyer-inverted-link;
}
.contrastLinkLightInverse {
    @include sassy-buyer-contrast-link-light-inverse;
}
.contrastLinkLight {
    @include sassy-buyer-contrast-link-light;
}
.contrastLinkAlternate {
    @include sassy-buyer-contrast-link-alternate;
}
.contrastLinkNoEffect {
    @include sassy-buyer-contrast-link-no-effect;
}
.standardLinkNoEffect {
    @include sassy-buyer-standard-link-no-effect;
}
.linkNoDecoration {
    @include sassy-buyer-link-no-decoration;
}
