$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_zindex';
@import '../../../mixins';

/* FlatVideo Icon block */
.videoIcon {
    position: absolute;
    box-sizing: content-box;
    @include sassy-z-index(bump);
    width: 23px;
    transform: scale(0.8);
    color: $sassy-color-dolphin;

    transition: opacity 0.1667s;
    opacity: 1;

    padding: $sassy-spacing-x-small;

    // To offset padding and margin set in ItemTileBase.scss
    top: -1 * $sassy-spacing-medium;
    left: -1 * $sassy-spacing-small;

    @include sassy-breakpoint-below(tablet-portrait) {
        top: -1 * $sassy-spacing-small;
        left: -1 * $sassy-spacing-x-small;
    }
}

.hideVideoIcon {
    opacity: 0;
}
