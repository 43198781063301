$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_fonts';

.swipeIndicator {
    @include sassy-font-body($size: medium);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: $sassy-spacing-x-small;
    color: $sassy-color-white;
    background-color: rgba($sassy-color-noir, 0.6);
    display: flex;
    align-items: center;
    pointer-events: none;
    justify-content: center;
    transition: 0.1s opacity ease, 0.1s visibility ease;
    opacity: 0;
    visibility: hidden;
    &.isVisible {
        opacity: 1;
        visibility: visible;
    }
}

.swipeIndicatorArrow {
    height: 16px;
    margin-left: $sassy-spacing-x-small;
}
