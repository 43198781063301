$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.retail {
    &.hasNet,
    &.hasSale,
    &.hasPrivateOffer {
        @include sassy-font-body(small);
        color: $sassy-color-satan;
    }
}

.price {
    .hasNet &,
    .hasSale &,
    .unpurchasable &,
    .hasPrivateOffer & {
        text-decoration: line-through;
    }
}

.blur {
    filter: blur(4px);
    user-select: none;
}

.net {
    &.hasPrivateOffer {
        @include sassy-font-body(small);
        color: $sassy-color-satan;
    }
}

.label {
    @include sassy-font-body(small);
    margin-left: 5px;

    &:nth-child(n + 3):last-child:not(:empty)::before {
        content: '|';
        margin-right: 5px;
    }
    &.hideSeparator::before {
        display: none;
    }
}

.expiration {
    @include sassy-font-body($size: small, $type: heavy);
    display: block;
    color: $sassy-color-noir;
}

.discount {
    margin-left: $sassy-spacing-x-small;

    .hasNet & {
        color: $sassy-color-trade-secondary;
    }
    .hasSale & {
        color: $sassy-color-alert-secondary;
    }
    .hasPrivateOffer & {
        color: $sassy-color-alert-secondary;
    }
}

.inline {
    display: inline;
}

.auctionSiderailRedesignContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.auctionSiderailRedesignLabel {
    @include sassy-font-body($type: heavy);
}

.auctionSiderailRedesignAmount {
    @include sassy-font-body($size: large);
    text-decoration: line-through;
}
