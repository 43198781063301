@import '~dibs-sassy/exports/_breakpoints';

$__INTERNAL_USE_ONLY_X_SMALL_IMAGE_SIZES__: (
    desktop: 148px,
    tablet-landscape: 90px,
    tablet-portrait: 90px,
);

$__INTERNAL_USE_ONLY_SMALL_IMAGE_SIZES__: (
    desktop: 160px,
    tablet-landscape: 130px,
    tablet-portrait: 122px,
);

$__INTERNAL_USE_ONLY_MEDIUM_IMAGE_SIZES__: (
    desktop: 220px,
    tablet-landscape: 180px,
    tablet-portrait: 140px,
);

$__INTERNAL_USE_ONLY_LARGE_IMAGE_SIZES__: (
    desktop: 360px,
    tablet-landscape: 180px,
    tablet-portrait: 180px,
);

$__INTERNAL_USE_ONLY_X_LARGE_IMAGE_SIZES__: (
    desktop: 550px,
    tablet-landscape: 450px,
    tablet-portrait: 350px,
);

@mixin __INTERNAL_USE_ONLY_SET_DIMENSIONS__($size) {
    max-width: $size;
    height: $size;
}

@mixin __INTERNAL_USE_ONLY_IMAGE_SIZING__($sizes) {
    @each $device, $size in $sizes {
        @if $device == desktop {
            @include __INTERNAL_USE_ONLY_SET_DIMENSIONS__($size);
        } @else {
            @include sassy-breakpoint-below($device) {
                @include __INTERNAL_USE_ONLY_SET_DIMENSIONS__($size);
            }
        }
    }
}

@mixin image-sizes() {
    &.imageXSmall {
        @include __INTERNAL_USE_ONLY_IMAGE_SIZING__($__INTERNAL_USE_ONLY_X_SMALL_IMAGE_SIZES__);
    }

    &.imageSmall {
        @include __INTERNAL_USE_ONLY_IMAGE_SIZING__($__INTERNAL_USE_ONLY_SMALL_IMAGE_SIZES__);
    }

    &.imageMedium {
        @include __INTERNAL_USE_ONLY_IMAGE_SIZING__($__INTERNAL_USE_ONLY_MEDIUM_IMAGE_SIZES__);
        @include sassy-breakpoint-below(mobile) {
            max-width: 130px;
            height: 130px;
        }
    }

    &.imageLarge {
        @include __INTERNAL_USE_ONLY_IMAGE_SIZING__($__INTERNAL_USE_ONLY_LARGE_IMAGE_SIZES__);
    }

    &.imageXLarge {
        @include __INTERNAL_USE_ONLY_IMAGE_SIZING__($__INTERNAL_USE_ONLY_X_LARGE_IMAGE_SIZES__);
    }
}
