$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';

/**
 * TODO: Fix webpack. Without this var webpack doesn't add userType specificity classes in storybook for carousel component.
 * Because of this all imports that have a userType specific styles override carousel component styles.
 */
$user-type: 'buyer';

$arrow-padding: $sassy-spacing-x-small;
$arrow-size: 26px;
$full-arrow-size: $arrow-size + $arrow-padding * 2;

.arrowLink {
    font-size: 0;
    padding: $arrow-padding;
    color: $sassy-color-satan;
    flex-shrink: 0;
}

.arrow {
    width: $arrow-size;
    height: $arrow-size;
}

.arrowFiller {
    width: $full-arrow-size;
    height: $full-arrow-size;
    flex-shrink: 0;
}
