$device-type: 'responsive';
$user-type: 'buyer';
$badge-small: 19px;
$badge-medium: 29px;

@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

:local {
    .badge {
        display: inline-block;
        height: $badge-small;
        min-width: $badge-small;
        border-radius: $badge-small * 0.5;
        background-color: $sassy-color-moonstone;
        color: $sassy-color-pitch-black;

        @if $user-type == 'dealer' {
            color: $sassy-color-white;
            background-color: $sassy-color-dealer-primary;
        } @else if $user-type == 'internal' {
            color: $sassy-color-white;
            background-color: $sassy-color-internal-primary;
        }

        &Medium {
            height: $badge-medium;
            min-width: $badge-medium;
            border-radius: $badge-medium * 0.5;
        }

        &Neutral {
            color: $sassy-color-pitch-black;
            background-color: $sassy-color-moonstone;
        }

        &Alert {
            background-color: $sassy-color-alert-primary;
            color: $sassy-color-white;
        }
        &Warning {
            background-color: $sassy-color-warning-primary;
        }

        &Pill {
            background-color: $sassy-color-buyer-tertiary;
            color: $sassy-color-pitch-black;

            @if $user-type == 'dealer' {
                color: $sassy-color-white;
                background-color: $sassy-color-dealer-primary;
            } @else if $user-type == 'internal' {
                color: $sassy-color-white;
                background-color: $sassy-color-internal-primary;
            }
        }

        &Interactive {
            // class can't contain only a conditional: wrong type definition generation
            cursor: default;
            @if $user-type == 'buyer' {
                background-color: $sassy-color-moonstone;
                transition: all 0.1s;
                cursor: pointer;

                &:hover {
                    background-color: $sassy-color-alabaster;
                }
            }
        }

        &White {
            color: $sassy-color-noir;
            background-color: $sassy-color-white;
        }

        &BuyerTertiary {
            background-color: $sassy-color-buyer-tertiary;
        }

        &DealerTertiary {
            color: $sassy-color-noir;
            background-color: $sassy-color-dealer-tertiary;
        }

        &Moonstone {
            background-color: $sassy-color-moonstone;
            color: $sassy-color-pitch-black;
        }

        &Cyan200 {
            color: $sassy-color-blue-950;
            background-color: $sassy-color-cyan-200;
        }

        &Yellow200 {
            background-color: $sassy-color-yellow-200;
            color: $sassy-color-yellow-950;
        }

        &Green200 {
            background-color: $sassy-color-green-200;
            color: $sassy-color-green-950;
        }

        &Red200 {
            background-color: $sassy-color-red-200;
            color: $sassy-color-red-900;
        }

        &AlertSecondary {
            background-color: $sassy-color-alert-secondary;
            color: $sassy-color-white;
        }
    }

    .badgeInner {
        @include sassy-font-body($size: x-small);
        display: flex;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        line-height: 1;
        letter-spacing: 0.5px;
        padding: 2px 5px 0;

        &Pill {
            padding: 2px $sassy-spacing-x-small 0;
        }

        &SmallHeavy {
            @include sassy-font-body($size: x-small, $type: heavy);
        }
        &SmallRegular {
            @include sassy-font-body($size: x-small);
        }

        &MediumRegular {
            @include sassy-font-body($size: small);
            padding-top: 0;
        }

        &MediumHeavy {
            @include sassy-font-body($size: small, $type: heavy);
            padding-top: 0;
        }

        &Capitalize {
            // class can't contain only a conditional: wrong type definition generation
            text-transform: none;
            @if $user-type == 'dealer' {
                text-transform: capitalize;
            }
        }

        &Uppercase {
            // class can't contain only a conditional: wrong type definition generation
            text-transform: none;
            @if $user-type == 'dealer' {
                @include sassy-font-sub-header($size: medium, $type: heavy);
            }
        }
    }
}
