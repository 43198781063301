$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_zindex';
@import '../variables';

$title-wrapper-base-height: 41px;

.byline {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: $info-line-height; // To keep the height for the later appearing creator/seller name
    color: $sassy-color-iron;
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;

    &.imageXLarge {
        @include sassy-font-body;
    }
}

.internalByline {
    @include sassy-font-body(medium);
    position: absolute;
    top: 16px;
    width: 80%;
    padding: $sassy-spacing-x-small;
    opacity: 0.9;
    color: $sassy-color-noir;
    background: $sassy-color-moonstone;
    display: flex;
    justify-content: space-between;

    &.isSbTile {
        @include sassy-z-index(bump);
        top: $sassy-spacing-small;
        margin: 0 $sassy-spacing-large 0 $sassy-spacing-small;
    }
}

.titleWrapper {
    min-height: $title-wrapper-base-height;
    &.hasByLine {
        min-height: calc(#{$title-wrapper-base-height} + #{$info-line-height});
    }
    &.titleOneLine {
        min-height: auto;
    }
}

.skusBadge {
    margin-top: $sassy-spacing-x-small;
}

.originalListPrice {
    color: $sassy-color-pitch-black;
}

.urgencySignalMt {
    margin-top: -4px;
}
