$device-type: 'responsive';
@import '../helpers/gapStyles/gapStyles.scss';

/**
 * TODO: Fix webpack. Without this var webpack doesn't add userType specificity classes in storybook for carousel component.
 * Because of this all imports that have a userType specific styles override carousel component styles.
 */
$user-type: 'buyer';

.carouselItem {
    @include item-gap;
    flex-shrink: 0;
    scroll-snap-stop: always;
    overflow: hidden;
    display: flex;
}

.carouselSnapItemStart {
    scroll-snap-align: start;
}

.carouselSnapItemEnd {
    scroll-snap-align: end;
}
