$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';

$iconSize: 12px;

.rulerIcon {
    margin: auto $sassy-spacing-x-small auto 0;
    width: $iconSize;
    height: $iconSize;
}
