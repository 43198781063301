$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_buyerLinks';

$circleSize: 20px;

.container {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
}

.swatchCircle {
    display: inline-block;
    width: $circleSize;
    height: $circleSize;
    border-radius: 50%;
    background-color: $sassy-color-moonstone;
    margin-right: $sassy-spacing-x-small;
    border: 1px solid $sassy-color-moonstone;
}

.swatchesCount {
    @include sassy-font-body($size: small);
    color: $sassy-color-noir;
    margin-left: $sassy-spacing-x-small;

    @include sassy-breakpoint-below(mobile) {
        margin-left: 0;
    }
}

.link {
    @include sassy-buyer-link-no-decoration;
    &:hover {
        color: currentColor;
    }
}
