$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '../helpers/gapStyles/gapStyles.scss';

/**
 * TODO: Fix webpack. Without this var webpack doesn't add userType specificity classes in storybook for carousel component.
 * Because of this all imports that have a userType specific styles override carousel component styles.
 */
$user-type: 'buyer';

.carouselWrapper {
    overflow: hidden;
    flex-grow: 1;
    align-self: stretch;
    display: flex;
    width: 100%;
}

.carousel {
    @include wrapper-gap;
    @include scroll-padding;
    display: flex;
    flex-grow: 1;
    width: 100%;

    &.slide {
        overflow: auto;
    }

    &.none,
    &.disableScroll {
        overflow: hidden;
    }

    &.horizontal {
        flex-direction: row;
        scroll-snap-type: x mandatory;
    }

    &.vertical {
        flex-direction: column;
        scroll-snap-type: y mandatory;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}
