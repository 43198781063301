$user-type: 'buyer';
$device-type: 'responsive';
/* SwiperItem with Video on hover */
.lazyVideoSpinner {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}
