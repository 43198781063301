$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.wrapper {
    @include sassy-font-body($type: none, $size: large);
    min-height: 27px; // font-size: 18 * line-height: 1.5
    &.smallText {
        @include sassy-font-body;
        min-height: 0;
    }
    &.largeHeavy {
        @include sassy-font-body($type: heavy, $size: large);
    }
}
.hold {
    color: $sassy-color-noir;
    @include sassy-font-body($type: heavy, $size: none);
}
.showCompactedPrice {
    @include sassy-font-body;
    color: $sassy-color-noir;
}
.sold,
.unavailable {
    color: $sassy-color-alert-secondary;
    text-transform: uppercase;
    display: inline;

    &:not(:only-child):after {
        content: '';
        display: inline-block;
        height: 12px;
        width: 1px;
        margin: 0 $sassy-spacing-x-small;
        background: $sassy-color-dolphin;
    }
}
.pur {
    color: $sassy-color-noir;
}
.countdown {
    color: $sassy-color-alert-secondary;
    margin-bottom: $sassy-spacing-x-small;
    @include sassy-font-body($size: small);
}
.icon {
    fill: $sassy-color-alert-secondary;
    height: 14px;

    &.smallText {
        height: 12px;
    }
}

.soldUnavailablePriceWrapper {
    display: block;
}
