$user-type: 'buyer';
$device-type: 'responsive';
@import '../mixins';

.placeholderHidden {
    visibility: hidden;
    opacity: 0;
}

.placeholder {
    @include image-sizes;
    position: absolute;
    z-index: 2;
    width: 100%;
    filter: blur(10px);
    > svg {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        height: auto;
        width: auto;
    }
    &.isNarrow > svg {
        height: 100%;
    }
}
