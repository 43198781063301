$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';

.link {
    fill: $sassy-color-dolphin;
    color: $sassy-color-dolphin;

    @include sassy-breakpoint-below(mobile) {
        position: absolute;
        width: 11px;
        height: 11px;
        right: 0;
        bottom: 15px;

        &.hideDivider {
            bottom: 9px;
        }

        &.isSbTile {
            position: static;
            bottom: 0;
            margin: 5px 0 $sassy-spacing-x-small $sassy-spacing-x-small;
        }
    }

    @include sassy-breakpoint-above(tablet-portrait) {
        display: block;
        width: $sassy-spacing-small;
        height: $sassy-spacing-small;
        margin-right: $sassy-spacing-x-small;

        &.isSbTile {
            margin: 5px 0 0 $sassy-spacing-x-small;
        }
    }
}

.isSbTile {
    fill: $sassy-color-storm;
    width: 12px;
    height: 12px;
    display: block;
    position: static;
}
