$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_webkitLineClamp';
@import '~dibs-sassy/exports/_button';

@mixin testParagraphBase {
    @include sassy-font-body($size: small, $type: none);
    margin-top: $sassy-spacing-small;

    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-body($size: x-small, $type: none);
        margin-top: $sassy-spacing-x-small;

        &:last-child {
            margin-bottom: $sassy-spacing-x-small;
        }
    }
}

.wrapper {
    &:focus {
        // do not show focus ring on when div is programmatically focused
        outline: none;
    }
    @include sassy-z-index(bump);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: none;

    &.isActive {
        display: block;
    }
}

.item {
    position: relative;
    width: 100%;
    height: 100%;
    padding: $sassy-spacing-large $sassy-spacing-medium;
    background: $sassy-color-alabaster;

    &.isSbTile {
        background: $sassy-color-white;
        padding: $sassy-spacing-larger $sassy-spacing-small $sassy-spacing-small
            $sassy-spacing-small;
    }

    @include sassy-breakpoint-below(tablet-portrait) {
        padding: $sassy-spacing-large $sassy-spacing-x-small $sassy-spacing-small;
    }
}

.close {
    @include sassy-unstyled-button;
    cursor: pointer;
    position: absolute;
    top: $sassy-spacing-small;
    right: $sassy-spacing-small;
    width: 15px;
    height: 15px;
    color: $sassy-color-noir;

    &:hover {
        color: $sassy-color-storm;
    }

    @include sassy-breakpoint-below(tablet-portrait) {
        top: $sassy-spacing-x-small;
        right: $sassy-spacing-x-small;
    }
}

.content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.title {
    @include sassy-font-body($type: none);
    margin-bottom: 16px;

    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-body($size: small, $type: none);
    }
}

.paragraph {
    margin-bottom: 16px;
    @include sassy-font-body($size: small, $type: none);

    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-body($size: x-small, $type: none);
        margin-bottom: $sassy-spacing-x-small;
    }
}

.testContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.testParagraphs {
    overflow: auto;

    :last-child {
        margin-bottom: $sassy-spacing-small;
    }
}

.testTitle {
    @include sassy-font-body($type: heavy);
    @include webkitLineClamp(2);
    overflow: hidden;

    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-body($size: x-small, $type: heavy);
    }
}

.testCreator {
    @include sassy-font-body($size: small, $type: none);

    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-body($size: x-small, $type: none);
    }
}

.testDescription {
    @include testParagraphBase;

    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-body($size: x-small, $type: normal);
        margin-bottom: $sassy-spacing-small;
    }
}

.testCategory {
    margin-top: 0;
    @include sassy-font-body($size: small, $type: normal);

    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-body($size: x-small, $type: none);
    }
}

.testParagraph {
    @include testParagraphBase;

    &::first-line {
        @include sassy-font-body($size: small, $type: heavy);

        @include sassy-breakpoint-below(tablet-portrait) {
            @include sassy-font-body($size: x-small, $type: heavy);
        }
    }
}
